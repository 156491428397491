import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useAuth } from "../hooks/use-auth";
import { useRouter } from "../hooks/use-router";
import { paths } from "../data/paths";

export const GuestGuard = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(() => {
    console.log("GuestGuard", children);
    if (isAuthenticated) {
      console.log("isAuthenticated", window.location.pathname);
      if (window.location.pathname === "/") {
        console.log("if pathname === /", window.location.pathname);
        router.replace(paths.clients.path);
      } else {
        console.log("else if pathname !== /", window.location.pathname);
        router.push(window.location.pathname);
      }
    } else {
      console.log("else !isAuthenticated", isAuthenticated);
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  if (!checked) {
    console.log("if !checked", checked);
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
