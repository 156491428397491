export const paths = {
  index: { path: "/", title: "Dashboard" },
  dashboard: { path: "/dashboard", title: "Dashboard" },

  clients: {
    path: "/clients",
    index: "/clients",
    edit: "/clients/:clientId/edit",
    title: "Clients",
  },
  settings: {
    title: "Settings",
    path: "/settings",
  },
  contracts: {
    title: "Contracts",
    path: "/contracts",
  },
  Workflows: {
    title: "Workflows",
    path: "/workflows",
    client: {
      path: "/workflows/client",
      title: "Client",
    },
    dispute: {
      path: "/workflows/dispute",
      title: "Dispute",
    },
  },
  Segments: { title: "Segments", path: "/segments" },
  Tasks: {
    title: "Tasks",
    path: "/tasks",
  },
  templates: {
    title: "Templates",
    path: "/template",
  },

  management: {
    title: "User Management",
    path: "/management",
  },

  import: {
    title: "Import",
    path: "/import",
    Import: { title: "Import", path: "/import" },
  },

  activity: {
    title: "Activity",
    path: "/activity",
    viewActivities: {
      path: "/activity/viewActivities",
      title: "View Activities",
    },
  },

  auth: {
    otp: { path: "/auth/otp", title: "OTP Login" },
  },
  profile: { path: "/profile", title: "Profile" },
};
